/* App.js */
/* @author: anghello */

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';
import 'styled-components/macro';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from 'react-cookie-consent';
import GoogleAnalytics from 'helpers/GoogleAnalytics';
import Loader from 'helpers/Loader';
import AppProvider from 'context/Provider';
import Routes from 'routes/Routes';

export default function App() {
  const cookieName = process.env.REACT_APP_COOKIE_CONSENT_NAME;
  
  GoogleAnalytics();

  return (
    <AppProvider>
      <Router>
        <Loader />
        <Routes />

        <CookieConsent
          location='bottom'
          buttonText='OK'
          cookieName={cookieName}
          style={{ background: '#2B373B', fontSize: '17px' }}
          buttonStyle={{ background: '#6415FF', color: '#ffffff', fontSize: '18px' }}
          expires={100}
          onAccept={() => {
            GoogleAnalytics();
          }}
        >
          O Smartlist coleta cookies para aprimorar a experiência do usuário
        </CookieConsent>

        <ToastContainer />
      </Router>
    </AppProvider>
  );
}
