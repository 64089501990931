/* GoogleAnalytics.js */

import { getCookieConsentValue } from 'react-cookie-consent';

export default function GoogleAnalytics() {
  const cookieName = process.env.REACT_APP_COOKIE_CONSENT_NAME;
  const GA = process.env.REACT_APP_ENABLE_GA;
  const cookieAccepted = getCookieConsentValue(cookieName);

  if (GA === 'true' && cookieAccepted) {

    const GA_ID = process.env.REACT_APP_GA_ID;
    window.gtag('js', new Date());
    window.gtag('config', GA_ID);
  }
}
