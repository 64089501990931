import React, { createContext, useState, useCallback, useContext } from 'react'
import api from 'services/api'

const AuthContext = createContext()
const APP_ID = process.env.REACT_APP_APP_ID

function AuthProvider({ children }) {
  const [loading, setLoading] = useState(null)
  const [lists, setLists] = useState([])

  const toggleLoading = useCallback((state) => {
    setLoading(state)
  }, [])

  const [authenticated, setAuthenticated] = useState(() => {
    const user = localStorage.getItem(`@${APP_ID}:user`)
    if (user) {
      const userData = JSON.parse(user)
      api.defaults.headers['login-key'] = userData.login_key
      return userData
    }
  })

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api.post('/login', {
        email,
        password
      })
      const { data } = response
      const saveData = { email: email, login_key: data.login }

      localStorage.setItem(`@${APP_ID}:user`, JSON.stringify(saveData))

      api.defaults.headers['login-key'] = saveData.login_key

      setAuthenticated(saveData)
    } catch (err) {
      return Promise.reject(err)
    }
  }, [setAuthenticated])

  const logout = useCallback(async () => {
    setAuthenticated()

    localStorage.setItem(`@${APP_ID}:user`, '')
    localStorage.removeItem(`@${APP_ID}:user`)
  }, [setAuthenticated])

  const getLists = useCallback(() => {
    toggleLoading(true)
    api.post('/get_user_lists', {})
      .then((response) => {
        if (response.data && response.data.items) {
          setLists(response.data.items)
        }
        toggleLoading(false)
      })
  }, [setLists, toggleLoading])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        signIn,
        logout,
        toggleLoading,
        loading,
        lists,
        getLists
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export { useAuth, AuthProvider }
