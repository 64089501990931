/* CalendarModal.js */
/* @author: anghello */

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import tw from 'twin.macro';
import ReactModalAdapter from 'helpers/ReactModalAdapter';
import { toast } from 'react-toastify';
import api from 'services/api';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay { ${tw`fixed inset-0 z-50 bg-gray-501`} }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 absolute inset-0 flex justify-center items-center rounded-lg bg-gray-700 outline-none shadow-2xl`}
    width: fit-content;
    height: fit-content;
  }
  .content { ${tw`w-full p-0`} }
`;

const formatDate = (obj) => {
  const { day, month, year } = obj;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
  return formattedDate;
}

const parseDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return { day, month, year };
}

export default ({ isOpen, toggle, item, callback}) => {
  const [selectedDay, setSelectedDay] = useState();

  useEffect(() => {
    if (item && item.date_assigned) {
      const date = parseDate(item.date_assigned.split(' ')[0]);
      setSelectedDay(date);
    }
  }, [item]);

  const update = useCallback(async (value) => {
    if (item && item.id && value) {
      
      const date = formatDate(value) + ' 00:00:00';

      api.post('/change_item_date_assigned', { item_id: item.id, date_assigned: date })
        .then((response) => {
          let message = 'Success'
          if (response && response.data && response.data.message) {
            message = response.data.message;
          }

          toast.success(message);

          if (toggle) toggle();
          if (callback) callback();
        })
        .catch((err) => {
          let message = 'Error';
          if (err && err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          toast.error(message);
        })
    }
  }, [item, toggle, callback]);

  return (
    <StyledModal
      closeTimeoutMS={0}
      className='mainHeroModal'
      isOpen={isOpen}
      onRequestClose={toggle}
      shouldCloseOnOverlayClick={true}
    >
      <div className='content'>
        <Calendar
          value={selectedDay}
          onChange={update}
          colorPrimary='#6415FF'
        />
        
      </div>
    </StyledModal>
  )
}
