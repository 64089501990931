import React from "react";
import tw from "twin.macro";
import "styled-components/macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Homepage";
import Features from "components/features/ThreeColWithSideImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/CtaHome";
import Footer from "components/footers/homepage";
import MetaTags from 'react-meta-tags';
import config from 'config/config';

export default () => {
  const enableAnimations = (process.env.REACT_APP_ENABLE_ANIMATIONS === 'true') ? true : false
  
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled={!enableAnimations}>
      <MetaTags>
        <title>{config.APPLICATION_TITLE} {config.APPLICATION_TITLE_SEPARATOR} O gerenciador de tarefas definitivo</title>
        <meta name="description" content="Smartlist é o gerenciador de tarefas definitivo: o único que você vai precisar pra se organizar"/>
        <meta name="robots" content="index, follow" />
      </MetaTags>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Uma plataforma <HighlightedText>completa.</HighlightedText>
          </>
        }
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Você tem <HighlightedText>Dúvidas ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "O Smartlist é fácil de usar?",
            answer:
              "Sim, o Smartlist foi concebido pra ser uma plataforma única de gerenciamento de tarefas e projetos. Acesse todas as funções com facilidade e tenha sempre tudo ao seu alcance."
          },
          {
            question: "Posso compartilhar minhas listas?",
            answer:
              "Em breve o compartilamento de listas e projetos estará disponível para todos. Fique de olho!"
          },
          {
            question: "Qual é o custo do Smartlist?",
            answer:
              "Por enquanto o Smartlist é GRATUITO! Mas isso não é definitivo. Ao realizar o seu cadastro você garante a gratuidade de todas as features disponíveis até o momento, e quando o Smartlist passar a ser cobrado você só terá restrições para as novas features implementadas."
          },
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
