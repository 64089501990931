/* List.js */
/* @author: anghello */

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import Header from 'components/headers/main.js'
import Footer from 'components/footers/main.js'
import Items from 'components/cards/Items'

import MetaTags from 'react-meta-tags';
import config from 'config/config';

const enableAnimations = (process.env.REACT_APP_ENABLE_ANIMATIONS === 'true') ? true : false;

export default () => {
  const { id } = useParams()
  const [name, setName] = useState('');

  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <AnimationRevealPage disabled={!enableAnimations}>
      <MetaTags>
        <title>{name} {config.APPLICATION_TITLE_SEPARATOR} {config.APPLICATION_TITLE}</title>
        <meta name="description" content=""/>
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <Header />

      <Items listId={id} parentSetName={setName} />

      <Footer />
    </AnimationRevealPage>
  )
}
