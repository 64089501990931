import React, { useCallback, useRef } from 'react';
import { FormErrorParagraph } from 'components/misc/Typography';
import tw from 'twin.macro';
import styled from 'styled-components';
import 'styled-components/macro';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useAuth } from 'context/Auth';

const Row = tw.div`flex gap-4 mb-3`;
const FullColumn = tw.div`relative lg:w-full`;
const HalfColumn = tw.div`relative lg:w-1/2`;

const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 px-4 py-2 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}`;

export default () => {
  const { toggleLoading, authenticated } = useAuth();

  const { register, errors, handleSubmit, watch, reset } = useForm()
  const password = useRef({});
  password.current = watch("password_new", "");

  const onSubmit = useCallback(async (data) => {
    const formData = {
      user_email: authenticated.email,
      old_password: data.password_current,
      new_password : data.password_new
    };

    toggleLoading(true)
    api.post('/change_user_password', formData)
      .then((response) => {
        let message = 'Sucesso';
        if (response && response.data && response.data.message) {
          message = response.data.message;
        }
        toggleLoading(false);
        toast.success(message);
        reset();
      })
      .catch((err) => {
        let message = 'Erro';
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message;
        }
        toggleLoading(false);
        toast.error(message);
      })
  }, [toggleLoading, authenticated, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <FullColumn>
          <Input
            name="password_current"
            id="password_current"
            type="password"
            placeholder="Senha atual"
            ref={register({
              required: 'Insira a senha atual',
              minLength: {
                value: 6,
                message: 'Insira a senha atual'
              }
            })}
          />
          {errors.password_current && (<FormErrorParagraph> {errors.password_current.message}</FormErrorParagraph>)}
        </FullColumn>
      </Row>
      <Row>
        <HalfColumn>
          <Input
            name="password_new"
            id="password_new"
            type="password"
            placeholder="Nova senha"
            ref={register({
              required: 'Insira a nova senha',
              minLength: {
                value: 6,
                message: 'Use uma senha de pelo menos 6 caracteres'
              }
            })}
          />
          {errors.password_new && (<FormErrorParagraph> {errors.password_new.message}</FormErrorParagraph>)}
        </HalfColumn>
        <HalfColumn>
          <Input
            name="password_confirm"
            id="password_confirm"
            type="password"
            placeholder="Confirme a nova senha"
            ref={register({
              required: 'Insira a confirmação',
              validate: value =>
                value === password.current || "As senhas não são iguais"
            })} />
          {errors.password_confirm && (<FormErrorParagraph> {errors.password_confirm.message}</FormErrorParagraph>)}
        </HalfColumn>
      </Row>

      <SubmitButton type="submit" onClick={handleSubmit(onSubmit)}>
        Atualizar senha
      </SubmitButton>
    </Form>
  );
}