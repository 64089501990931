/* ListsComponent.js */
/* @author: anghello */

import React, { useCallback, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons';
import { useAuth } from 'context/Auth';
import { useHistory } from 'react-router-dom';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-12`;
const ColumnContainer = styled.div`${tw`flex flex-col items-start md:items-stretch md:flex-row flex-wrap md:justify-start max-w-screen-lg mx-auto max-w-full sm:items-stretch`}`;
const Column = styled.div`${tw`w-full md:w-1/2 lg:w-1/4 max-w-sm mt-4`}`;
const HeadingContainer = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between mb-1`;
const Heading = tw.h2`text-xl sm:text-3xl tracking-wide text-center`;
const Card = tw.div`h-full flex! flex-col border max-w-sm rounded-tl-4xl rounded-br-5xl relative focus:outline-none mx-2`;
const TextInfo = tw.div`py-5 px-4`;
const TitleContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2 truncate`;
const Title = tw.h5`text-2xl`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const CountSpan = tw.span`text-xs leading-loose px-2 py-1 bg-primary-600 rounded text-white`;
const CountDoneSpan = tw.span`text-xs leading-loose px-2 py-1 bg-green-600 rounded text-white ml-2`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded rounded-none rounded-br-4xl py-2 sm:py-3`;

export default ({ title }) => {
  const history = useHistory();

  const { lists, getLists } = useAuth();
  useEffect(() => getLists(), [getLists]);

  const openList = useCallback((id) => history.push(`/list/${id}`), [history]);

  return (
    <Container>
      <Content>
        {title && (
          <HeadingContainer>
            <Heading>{title}</Heading>
          </HeadingContainer>
        )}

        <ColumnContainer>
          {lists.map((item) => (
            <Column key={item.id}>
              <Card>
                <TextInfo>
                  {item.name && (
                    <TitleContainer>
                      <Title>
                        {item.name}
                      </Title>
                    </TitleContainer>
                  )}

                  <CountSpan>{item.count}</CountSpan>
                  <CountDoneSpan>{item.count_done}</CountDoneSpan>

                  {item.description && (
                    <Description>{item.description}</Description>
                  )}

                </TextInfo>

                <PrimaryButton onClick={() => openList(item.id)}>Abrir</PrimaryButton>
              </Card>
            </Column>
          ))}
        </ColumnContainer>

      </Content>
    </Container>
  )
}
