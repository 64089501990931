import React, { useCallback } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import 'styled-components/macro'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/Auth'

const Container = tw.div`relative mt-6 sm:mt-2`
const ColumnContainer = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto my-2`
const Column = tw.div`relative w-full text-center max-w-lg mx-auto lg:max-w-none lg:text-left`

const Actions = styled.div`${tw`relative max-w-full text-center mx-auto lg:mx-0`}`
const Input = tw.input`sm:pr-48 pl-4 py-3 sm:py-3 rounded-md border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`
const Button = tw.button`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-md py-2 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`

export default ({ callback, listId }) => {
  const { toggleLoading } = useAuth()
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = useCallback(async (data) => {
    toggleLoading(true)
    api.post('/create_item', { title: data.item_title, list_id: listId })
      .then((response) => {
        let message = 'Success'
        if (response && response.data && response.data.message) {
          message = response.data.message
        }
        toast.success(message)
        reset()
        if (callback) callback()
      })
      .catch((err) => {
        let message = 'Error'
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message
        }
        toast.error(message)
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [listId, reset, callback, toggleLoading])

  return (
    <Container>
      <ColumnContainer>
        <Column>
          <Actions>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <Input
                type="text"
                placeholder="Para fazer..."
                id="item_title"
                name="item_title"
                ref={register({
                  required: 'Required'
                })}
              />
              <Button onClick={handleSubmit(onSubmit)}>Criar</Button>
            </form>
          </Actions>
        </Column>
      </ColumnContainer>
    </Container>
  )
}