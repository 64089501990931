import React from "react";
import tw from "twin.macro";
import "styled-components";
import "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import ChangePassword from "components/forms/ChangePassword";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const ColumnContainer = tw.div`flex`;
const Column = tw.div``;

const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const TitleText = tw.span`text-lg lg:text-xl font-semibold`;
const PageContainer = tw.dl`mt-12`;
const FormContainer = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const FormContent = tw.div`mt-4`;

export default ({
  heading = "Conta",
  description = "Modifique suas informações"
}) => {
  return (
    <Container>
      <Content>
          <ColumnContainer>
          <Column tw="w-full">
            <Heading>{heading}</Heading>
            <Description>{description}</Description>

            <PageContainer>

              <FormContainer>
                <TitleText>OI</TitleText>
              </FormContainer>

              <FormContainer>
                <TitleText>Segurança</TitleText>
                <FormContent>
                  <ChangePassword />
                </FormContent>
              </FormContainer>
              
            </PageContainer>
          </Column>
        </ColumnContainer>
      </Content>
    </Container>
  );
};
