import React, { useCallback } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import tw from 'twin.macro'
import ReactModalAdapter from 'helpers/ReactModalAdapter'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'
import { FormErrorParagraph2 as FormErrorParagraph } from 'components/misc/Typography'

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/Auth'

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 bg-gray-501`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-700 outline-none shadow-2xl`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-100 text-white`
const FormContainer = styled.div `
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const InputContainer = tw.div `relative pt-5 pb-0 mt-6`
const ColumnContainer = tw.div`flex flex-col sm:flex-row justify-between`
const Column = tw.div`sm:w-5/12 flex flex-col`
const Input = tw.input`px-2`
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-primary-500 text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-primary-900 hocus:-translate-y-px hocus:shadow-xl`

export default ({
    isOpen,
    toggle,
    callback
}) => {

    const { register, errors, handleSubmit } = useForm()
    const { getLists } = useAuth()

    const onSubmit = useCallback( async (data) => {
        api.post('/create_list', { name: data.list_name })
            .then((response) => {
                let message = 'Success'
                if (response && response.data && response.data.message) {
                    message = response.data.message
                }
                toast.success(message)
                if (toggle) toggle()
                if (callback) callback()
                getLists()
            })
            .catch((err) => {
                let message = 'Error'
                if (err && err.response && err.response.data && err.response.data.message) {
                    message = err.response.data.message
                }
                toast.error(message)
            })
    }, [toggle, callback, getLists])

    return (
        <StyledModal
            closeTimeoutMS={300}
            className='mainHeroModal'
            isOpen={isOpen}
            onRequestClose={toggle}
            shouldCloseOnOverlayClick={true}
            >
            <CloseModalButton onClick={toggle}>
                <CloseIcon tw='w-6 h-6' />
            </CloseModalButton>

            <div className='content'>
                <FormContainer>
                    <div tw='mx-auto max-w-4xl'>
                        <h2>Criar lista</h2>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                            <ColumnContainer>
                                <Column>
                                    <InputContainer>
                                        <Input 
                                        id='list_name'
                                        name='list_name'
                                        type='text'
                                        autoComplete='off'
                                        placeholder='Nome da lista'
                                        ref={register({
                                            required: 'O nome da lista é obrigatório',
                                            minLength: {
                                                value: 3,
                                                message: 'Use um nome com pelo menos 3 caracteres'
                                            }
                                        })} />
                                    </InputContainer>
                                    {errors.list_name && ( <FormErrorParagraph> { errors.list_name.message }</FormErrorParagraph> )}
                                </Column>
                            </ColumnContainer>

                            <SubmitButton onClick={handleSubmit(onSubmit)}>Criar</SubmitButton>
                        </form>
                    </div>
                </FormContainer>
            </div>
        </StyledModal>
    )
}