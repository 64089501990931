import React from 'react'
import 'styles/loader.css'

import { useAuth } from 'context/Auth'

export default () => {
    const { loading } = useAuth()

    return (
        <>
            {loading && (
                <div className={"loader_container " + (loading ? '' : 'hidden')}>
                    <div className="loader"></div>
                </div>
            )}
        </>
    )
}