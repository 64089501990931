import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import 'styled-components/macro'
import Header from 'components/headers/main.js'
import Footer from 'components/footers/main.js'

// import ContactUsForm from 'components/forms/SupportContactForm';
import ContactDetails from 'components/cards/SupportContactDetail'

import MetaTags from 'react-meta-tags';
import config from 'config/config';


export default () => {
  const enableAnimations = (process.env.REACT_APP_ENABLE_ANIMATIONS === 'true') ? true : false

  return (
    <AnimationRevealPage disabled={!enableAnimations}>
      <MetaTags>
        <title>Suporte {config.APPLICATION_TITLE_SEPARATOR} {config.APPLICATION_TITLE}</title>
        <meta name="description" content=""/>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="/suporte" />
      </MetaTags>
      <Header />
      {/* <ContactUsForm /> */}
      <ContactDetails />
      <Footer />
    </AnimationRevealPage>
  )
}