import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import Header from 'components/headers/main.js'
import Footer from 'components/footers/main.js'
import UserAccount from 'components/user/UserAccount';
import MetaTags from 'react-meta-tags';
import config from 'config/config';

export default () => {
  const enableAnimations = (process.env.REACT_APP_ENABLE_ANIMATIONS === 'true') ? true : false

  return (
    <AnimationRevealPage disabled={!enableAnimations}>
      <MetaTags>
        <title>Conta {config.APPLICATION_TITLE_SEPARATOR} {config.APPLICATION_TITLE}</title>
        <meta name="description" content=""/>
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <Header />

      <UserAccount />

      <Footer />
    </AnimationRevealPage>
  )
}
