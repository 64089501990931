import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from 'context/Auth'

export default function RouteWrapper({
    component: Component,
    isPrivate,
    isPublic,
    isLogin,
    ...rest
}) {
    const { authenticated } = useAuth()

    let signed = null
    if (authenticated) {
        signed = true
    } else {
        signed = false
    }

    if (!signed && isPrivate) {
        return <Redirect to='/login' />
    }
    if (signed && isLogin) {
        return <Redirect to='/app' />
    }

    return (
        <Route {...rest}>
            <Component />
        </Route>
    )
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

RouteWrapper.defaultProps = {
    isPrivate: false,
}